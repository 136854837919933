import React from "react";
import { BsInstagram, BsTwitterX } from "react-icons/bs";
import { BsFacebook } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";

const Footer = () => {
  return (
    <footer class="footer pt-10">
      <div class="container">
        <div class="row">
          <div class="col-xl-3 col-lg-4 col-md-6">
            <div
              class="footer-widget mb-60 wow fadeInLeft"
              data-wow-delay=".2s"
            >
              <a href="index.html" class="logo mb-10">
                <img
                  src="images\logo\logo.png"
                  alt="logo"
                  style={{ height: "150px" }}
                />
              </a>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-6">
            <div class="footer-widget mb-20 wow fadeInUp" data-wow-delay=".4s">
              <h4 style={{ color: "#446497" }}>Electronics Division</h4>
              <ul class="footer-links">
                <li>
                  <p>Solar Energy</p>
                </li>
                <li>
                  <p>Electronic Enventions</p>
                </li>
                <li>
                  <p>Electric Solutions</p>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-6">
            <div class="footer-widget mb-20 wow fadeInUp" data-wow-delay=".6s">
              <h4 style={{ color: "#446497" }}>Software Division</h4>
              <ul class="footer-links">
                <li>
                  <p>Web Development</p>
                </li>
                <li>
                  <p>Mobile Application</p>
                </li>
                <li>
                  <p>ERP Systems</p>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-6">
            <div
              class="footer-widget mb-20 wow fadeInRight"
              data-wow-delay=".8s"
            >
              <h4 style={{ color: "#446497" }}>Reach Us</h4>
              <ul class="footer-contact">
                <li>
                  <p>+961-03644705</p>
                </li>
                <li>
                  <p>info@hexapi.tech</p>
                </li>
                <li>
                  <p>1803,West Bekaa,Lebanon</p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="copyright-area">
          <div class="row align-items-center">
            <div class="col-md-6">
              <div class="footer-social-links">
                <ul class="d-flex">
                  <li>
                    <a href="https://instagram.com/hexapi.tech">
                      <BsInstagram />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/HexaPiTech">
                      <BsFacebook />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/hexapi-tech/">
                      <BsLinkedin />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/@hexapitech">
                      <BsYoutube />
                    </a>
                  </li>
                  <li>
                    <a href="https://x.com/HexaPiTech">
                      <BsTwitterX />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-6">
              <p class="wow fadeInUp" data-wow-delay=".3s">
                2020-2024 © HexaPi Tech
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
