import "./App.css";
import About from "./components/About";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import { BrowserRouter } from "react-router-dom";
import Navbar from "./components/Navbar";
import Portfolio from "./components/Portfolio";
import Services from "./components/Services";
import Breadcrumb from "./components/Breadcrumb";

function App() {
  return (
    <div className="App" style={{ backgroundColor: "#446497" }}>
      <BrowserRouter>
        <Navbar />
        <Breadcrumb />
        <Hero />
        <About />
        <Services />
        <Portfolio />
        <Contact />
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
