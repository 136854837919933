import React from "react";

const Breadcrumb = () => {
  return (
    <nav aria-label="breadcrumb">
      <ol
        className="breadcrumb"
        style={{
          backgroundColor: "#446497",
          paddingTop: "100px",
          marginTop: "10px",
          marginBottom: "10px",
          marginLeft: "10px",
          marginRight: "10px",
        }}
      >
        <li
          className="breadcrumb-item"
          style={{ color: "white", textAlign: "center" }}
        >
          📣 Announcing our revolutionary AI and Technology curriculum for
          schools
        </li>
      </ol>
    </nav>
  );
};

export default Breadcrumb;
