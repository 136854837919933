import React from "react";

const Navbar = () => {
  return (
    <header class="header navbar-area">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-12">
            <nav class="navbar navbar-expand-lg">
              <a class="navbar-brand" href="index.html">
                <img
                  src="images\logo\logo.png"
                  alt="logo"
                  style={{ height: "60px" }}
                />
              </a>
              {/*<button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="toggler-icon">About</span>
                <span class="toggler-icon">Services</span>
                <span class="toggler-icon">Portfolio</span>
                <span class="toggler-icon">Contact</span>
              </button>
*/}
              <div
                class="collapse navbar-collapse sub-menu-bar"
                id="navbarSupportedContent"
              >
                <ul id="nav" class="navbar-nav ms-auto">
                  <li class="nav-item">
                    <li class="nav-item">
                      <a class="nav-item" href="#home">
                        Home
                      </a>
                    </li>
                  </li>

                  <li class="nav-item">
                    <a class="nav-item" href="#about">
                      About
                    </a>
                  </li>

                  <li class="nav-item">
                    <a class="nav-item" href="#service">
                      Services
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-item" href="#portfolio">
                      Portfolio
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-item" href="#contact">
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
