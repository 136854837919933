import React from "react";
import { FaSolarPanel } from "react-icons/fa";
import { FaRobot } from "react-icons/fa";
import { IoHardwareChipOutline } from "react-icons/io5";
import { CgWebsite } from "react-icons/cg";
import { BiMobileAlt } from "react-icons/bi";
import { BiDollarCircle } from "react-icons/bi";

const Services = () => {
  return (
    <section id="service" class="service-section pt-120 pb-80 ">
      <div class="container">
        <div class="row">
          <div class="col-xl-6 col-lg-7 col-md-9 mx-auto">
            <div class="section-title text-center mb-55">
              <span
                class="wow fadeInDown"
                data-wow-delay=".2s"
                style={{ fontSize: "50px" }}
              >
                Our Divisions
              </span>
            </div>
          </div>
        </div>
        <h3 style={{ textAlign: "center", marginTop: "-15px" }}>
          Division of Electronic Innovations
        </h3>
        <br></br>
        <h6 style={{ textAlign: "center", marginTop: "-15px" }}>
          Team Led by Engineer Samir Rizk
        </h6>
        <br></br>
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="service-box box-style">
              <div class="service-icon box-icon-style pulsate-bck">
                <FaSolarPanel style={{ fontSize: "40px", color: "#4a5f9e" }} />
              </div>
              <div class="box-content-style service-content">
                <h4 style={{ color: "#446497" }}>Solar Energy</h4>
                <p>
                  We have an experienced team in installing solar energy
                  systems, also we have partnered with Marvel Tech agent in
                  Lebanon and we have accomplished over 40 solar projects.{" "}
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="service-box box-style">
              <div class="service-icon box-icon-style pulsate-bck">
                <IoHardwareChipOutline
                  style={{ fontSize: "40px", color: "#4a5f9e" }}
                />
              </div>
              <div class="box-content-style service-content">
                <h4 style={{ color: "#446497" }}>Electronic Inventions</h4>
                <p>
                  Our department for electronic innovations has a wide
                  experience in electric wiring, micro-controllers, and
                  automation solutions besides solar systems.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="service-box box-style">
              <div class="service-icon box-icon-style pulsate-bck">
                <FaRobot style={{ fontSize: "40px", color: "#4a5f9e" }} />
              </div>
              <div class="box-content-style service-content">
                <h4 style={{ color: "#446497" }}>Electric Solutions</h4>
                <p>
                  We provide support for businesses or individuals seeking to
                  optimize their technology to help users select the most
                  suitable hardware components for their specific requirements.
                </p>
              </div>
            </div>
          </div>
        </div>
        <h3 style={{ textAlign: "center" }}>Division of Software Solutions</h3>
        <br></br>

        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="service-box box-style">
              <div class="service-icon box-icon-style pulsate-bck">
                <CgWebsite style={{ fontSize: "40px", color: "#4a5f9e" }} />
              </div>
              <div class="box-content-style service-content">
                <h4 style={{ color: "#446497" }}>Web Development</h4>
                <p>
                  We have a dedicated team for every project. We have 2 teams
                  for full stack web development, other for the UI/UX design,
                  and one for cloud solutions and hosting
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="service-box box-style">
              <div class="service-icon box-icon-style pulsate-bck">
                <BiMobileAlt style={{ fontSize: "40px", color: "#4a5f9e" }} />
              </div>
              <div class="box-content-style service-content">
                <h4 style={{ color: "#446497" }}>Mobile Development</h4>
                <p>
                  We are really enjoying the idea of releasing the same software
                  project on android devices, iPhone so our talented team
                  publishes your software via React Native and Flutter
                  framework.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="service-box box-style">
              <div class="service-icon box-icon-style pulsate-bck">
                <BiDollarCircle
                  style={{ fontSize: "40px", color: "#4a5f9e" }}
                />
              </div>
              <div class="box-content-style service-content">
                <h4 style={{ color: "#446497" }}>ERP Systems</h4>
                <p>
                  From finance and human resources to supply chain management
                  and customer relations, HexaPi Tech empowers businesses to
                  streamline operations, make informed decisions, and drive
                  growth{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
