import React from "react";
import ModalVideo from "react-modal-video";
import { MdPlayCircleOutline } from "react-icons/md";
import { IconButton } from "@material-ui/core";

const Hero = () => {
  const [isOpen, setOpen] = React.useState(false);
  return (
    <center>
      <section id="home" class="hero-section">
        <div class="container">
          <div class="row align-items-center">
            <ModalVideo
              channel="youtube"
              autoplay
              isOpen={isOpen}
              videoId="-lbEOuessUs"
              onClose={() => setOpen(false)}
            />
            <div class="col-xl-5 col-lg-6">
              <div class="hero-content-wrapper scale-in-center">
                <h1
                  class="mb-25 wow fadeInDown "
                  data-wow-delay=".2s"
                  style={{ fontSize: "3rem" }}
                >
                  HexaPi Tech
                </h1>
                <p class="mb-35 wow fadeInLeft " data-wow-delay=".4s">
                  We develop innovative and customized electronic and software
                  solutions. From prototyping to deployment, HexaPi Tech is the
                  only partner you will need.
                </p>
                <a href="#contact" class="theme-btn">
                  Get in Touch
                </a>
              </div>
            </div>
            <div class="col-xl-7 col-lg-6">
              <div class="hero-img">
                <div class="d-inline-block hero-img-center">
                  {" "}
                  <img
                    src="img\hero\hero-img.png"
                    alt=""
                    class=" image wow"
                    data-wow-delay=".5s"
                  />
                  <div class="video-btn">
                    <IconButton onClick={() => setOpen(true)}>
                      <MdPlayCircleOutline
                        style={{ fontSize: "50px", color: "#4a5f9e" }}
                        className="pulsate-bck"
                      />
                    </IconButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </center>
  );
};

export default Hero;
