import React from "react";
import { BsFillTelephoneInboundFill } from "react-icons/bs";
import { ImLocation } from "react-icons/im";
import { AiOutlineSchedule } from "react-icons/ai";
import ImageGallery from "react-image-gallery";

const Contact = () => {
  const images = [
    {
      original: "../img/contact/safe_plug.png",
      thumbnail: "../img/contact/safe_plug.png",
    },
  ];
  return (
    <section
      id="contact"
      class="contact-section cta-bg img-bg pt-110 pb-100"
      style={{ backgroundColor: "#f0f5fc" }}
    >
      <div class="container">
        <div class="row">
          <div class="col-xl-4">
            <div class="contact-item-wrapper">
              <div class="row">
                <div class="col-12 col-md-6 col-xl-12">
                  <div class="contact-item">
                    <div class="contact-icon">
                      <BsFillTelephoneInboundFill />
                    </div>
                    <div class="contact-content">
                      <h4>Contact</h4>
                      <p>+961-03644705</p>
                      <p>info@hexapi.tech</p>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-xl-12">
                  <div class="contact-item">
                    <div class="contact-icon">
                      <ImLocation />
                    </div>
                    <div class="contact-content">
                      <h4>Address</h4>
                      <p>West Bekaa,Lebanon </p>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-xl-12">
                  <div class="contact-item">
                    <div class="contact-icon">
                      <AiOutlineSchedule />
                    </div>
                    <div class="contact-content">
                      <h4>Working Hours</h4>
                      <p>Monday-Friday </p>
                      <p>Office time: 8:00 AM - 4:00 PM</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-8 image-gallery-wrapper">
            <ImageGallery items={images} showPlayButton={false} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
