import * as React from "react";
const Portfolio = () => {
  return (
    <center style={{ backgroundColor: "#f0f5fc" }}>
      <section
        id="portfolio"
        class="d-inline-block wow "
        style={{ backgroundColor: "#f0f5fc" }}
      >
        <div
          class="section-title text-center mb-55"
          style={{ textAlign: "center" }}
        >
          <span
            class="wow fadeInDown"
            data-wow-delay=".2s"
            style={{ fontSize: "50px" }}
          >
            Our Portfolio
          </span>
        </div>
        <div class="cards-list ">
          <div class="card ">
            <div class="card_image">
              <img src="img\portfolio\col2-row2a(1).png" alt="" />
            </div>
            <p>HexaPi Delivery</p>
            <div class="button-container">
              <a
                href="https://hexapi.tech/delivery.html"
                target="_blank"
                rel="noopener noreferrer"
                class="button1"
              >
                Read More
              </a>
              <a
                href="https://hexapi.tech/delivery"
                target="_blank"
                rel="noopener noreferrer"
                class="button1"
              >
                Try Demo
              </a>
            </div>
          </div>

          <div class="card ">
            <div class="card_image">
              {" "}
              <img src="img\portfolio\col3-row3.png" alt="" />{" "}
            </div>
            <p>HexaPi Auction</p>
            <div class="button-container">
              <a
                href="https://hexapi.tech/auction.html"
                target="_blank"
                rel="noopener noreferrer"
                class="button1"
              >
                Read More
              </a>
            </div>
          </div>

          <div class="card ">
            <div class="card_image">
              <img src="img\portfolio\col2-row2a(2).png" alt="" />
            </div>
            <p>Smart School</p>
            <div class="button-container">
              <a
                href="https://hexapi.tech/school.html"
                target="_blank"
                rel="noopener noreferrer"
                class="button1"
              >
                Read More
              </a>
              <a
                href="https://e-school-a0cd4.web.app/#/"
                target="_blank"
                rel="noopener noreferrer"
                class="button1"
              >
                Try Demo
              </a>
            </div>
          </div>
        </div>

        <div class="cards-list">
          <div class="card">
            <div class="card_image">
              {" "}
              <img src="img\portfolio\col2-row2a(3).png" alt="" />{" "}
            </div>
            <p>NSSF: Appointment Booking</p>
            <div class="button-container">
              <a
                href="https://hexapi.tech/booking.html"
                target="_blank"
                rel="noopener noreferrer"
                class="button1"
              >
                Read More
              </a>
            </div>

            <div class="card_title title-white"></div>
          </div>

          <div class="card">
            <div class="card_image">
              <img src="img\portfolio\IMG_20220511_141521_770.jpg" alt="" />
            </div>
            <p style={{ marginLeft: "-20px" }}>Solar Energy</p>
            <div class="button-container">
              <a
                href="https://hexapi.tech/solar/"
                target="_blank"
                rel="noopener noreferrer"
                class="button1"
              >
                Read More
              </a>
            </div>
          </div>

          <div class="card">
            <div class="card_image">
              <img src="img\portfolio\My project.png" alt="" />
            </div>

            <p>Control & Automation</p>
          </div>

          <div class="cards-list ">
            <div class="card">
              <div class="card_image">
                {" "}
                <img
                  src="img\portfolio\Safe Plug Portfolio(1).png"
                  alt=""
                />{" "}
              </div>
              <p>Safe Plug Invention</p>
              <div class="button-container">
                <a
                  href="https://youtu.be/3BU2JRAVFCM"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="button1"
                >
                  Read More
                </a>
              </div>

              <div class="card_title title-white"></div>
            </div>

            <div class="card">
              <div class="card_image">
                <img
                  src="img\portfolio\s-l640-2431179748.jpeg"
                  alt=""
                  style={{ height: "180px" }}
                />
              </div>
              <p>Automatic Stair Lights</p>
              <div class="button-container">
                <a
                  href="https://www.facebook.com/HexaPiTech/photos/a.111100613798111/539765904264911/"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="button1"
                >
                  Read More
                </a>
              </div>
            </div>

            <div class="card">
              <div class="card_image">
                <img
                  src="img\portfolio\Graduation-Cap-PNG-Image-575089638.png"
                  alt=""
                />
              </div>
              <p>Summer Internships</p>
              <a
                href="https://m.facebook.com/story.php?story_fbid=pfbid0Q7jWnoGEj5jWnGgXXkDnUFjriD8GkQVLweqGZ8kJ6NgF6fwcnb25m3XKMp7yuGYal&id=100029105125143&mibextid=Nif5oz"
                target="_blank"
                rel="noopener noreferrer"
                class="button1"
              >
                Read More
              </a>
            </div>
          </div>
        </div>
      </section>
    </center>
  );
};

export default Portfolio;
