import React from "react";

const About = () => {
  return (
    <center>
      <section id="about" class="pt-1" style={{ backgroundColor: "#f0f5fc" }}>
        <div class="about-section">
          <div class="container">
            <div class="row">
              <div class="col-xl-6 col-lg-6">
                <div class="about-img-wrapper">
                  <div
                    class="about-img position-relative d-inline-block wow"
                    data-wow-delay=".3s"
                  >
                    <img src="img\portfolio\about-image.png" alt="" />

                    <div class="about-experience">
                      <h3>4+ Years Of Working Experience</h3>
                      <p>
                        Our service is partnership-oriented: your success is
                        ours as well. If you are looking to move in new
                        directions, HexaPi is your “go-to” option.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6">
                <div class="about-content-wrapper">
                  <div class="section-title">
                    <span class="wow fadeInUp" data-wow-delay=".2s">
                      About Us
                    </span>
                    <h2 class="mb-20 wow fadeInRight" data-wow-delay=".4s">
                      We Are HexaPi
                    </h2>
                  </div>
                  <div class="about-content">
                    <p class="mb-25 wow fadeInUp" data-wow-delay=".6s">
                      HexaPi Tech is an official Lebanese registered company
                      (Registration Number: 3792261) that aims to innovate
                      digital and smart solutions for all companies and
                      individuals. It was founded in 2020 by both engineers
                      Samir Rizk and Yasser Haidar. With our unique Electricity
                      and Software departments, we are capable of offering our
                      clients the ideal solution for their problems,
                    </p>
                    <div class="counter-up wow fadeInUp" data-wow-delay=".5s">
                      <div class="counter">
                        <span
                          id="secondo"
                          class="countup count color-1"
                          cup-end="30"
                          cup-append="k"
                          style={{ color: "#446497" }}
                        >
                          23
                        </span>
                        <h4>Happy Clients</h4>
                        <p>
                          {" "}
                          <br class="d-none d-md-block d-lg-none d-xl-block" />{" "}
                        </p>
                      </div>
                      <div class="counter">
                        <span
                          id="secondo"
                          class="countup count color-2"
                          cup-end="42"
                          cup-append="k"
                          style={{ color: "#446497" }}
                        >
                          27
                        </span>
                        <h4>Projects Done</h4>
                        <p>
                          <br class="d-none d-md-block d-lg-none d-xl-block" />{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </center>
  );
};

export default About;
